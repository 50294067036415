/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export type CountryCode = string;

export class CountryData {
	public commonName: string;

	constructor(
		private readonly countryCode: CountryCode,
		countryData: any,
	) {
		if (countryData) {
			this.commonName = countryData.name.common;
		} else {
			this.commonName = 'Unknown country name';
		}
	}

	public get code(): string {
		return this.countryCode;
	}

	public toString(): string {
		return this.commonName;
	}
}

export class Country {}
